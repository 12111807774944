import React from 'react'
import tw, { styled } from 'twin.macro'
import PropTypes from 'prop-types'

const StyledButton = styled.button(({ size = 'base', color }) => [
  tw`py-2 px-4 bg-accent-500 border-2 border-accent-700 hover:bg-accent-700 text-white rounded-lg`,
  size === 'xl' && tw`py-4 px-8 text-xl`,
  size === 'sm' && tw`py-2 px-4`,
  color === 'secondary' &&
    tw`bg-secondary-700 hover:bg-secondary-900 border-secondary-900`,
])

const ContainedButton = ({ size, color = 'primary', children, ...props }) => {
  return (
    <StyledButton size={size} color={color} {...props}>
      {children}
    </StyledButton>
  )
}

ContainedButton.propTypes = {
  size: PropTypes.oneOf(['xl', 'base', 'sm']),
  color: PropTypes.oneOf(['primary', 'secondary']),
}

export default ContainedButton
