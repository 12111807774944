import * as React from "react";

function ImmersedInCulture(props) {
  return (
    <svg
      id="prefix__Layer_1"
      data-name="Layer 1"
      viewBox="0 0 247.19 204.01"
      {...props}
    >
      <defs>
        <style>
          {
            ".prefix__cls-3,.prefix__cls-4{isolation:isolate}.prefix__cls-4{font-size:43px;font-family:Helvetica;letter-spacing:.01em;fill:#fff}"
          }
        </style>
      </defs>
      <path
        id="prefix__Rectangle_163"
        data-name="Rectangle 163"
        fill="#b70e0e"
        d="M0 0h247.19v49.14H0z"
      />
      <path
        id="prefix__Rectangle_164"
        data-name="Rectangle 164"
        fill="#09426a"
        d="M0 93h247.19v49.14H0z"
      />
      <g id="prefix__IMMERSED" className="prefix__cls-3">
        <text className="prefix__cls-4" transform="translate(6.11 39.33)">
          {"IMMERSED"}
        </text>
      </g>
      <text
        transform="translate(102.27 85.33)"
        style={{
          isolation: "isolate",
        }}
        fontSize={43}
        fontFamily="Helvetica"
        letterSpacing=".01em"
        fill="#09426a"
        id="prefix__IN"
      >
        {"IN"}
      </text>
      <g id="prefix__AMERICAN" className="prefix__cls-3">
        <text className="prefix__cls-4" transform="translate(8.5 131.33)">
          {"AMERICAN"}
        </text>
      </g>
      <text
        transform="translate(23.25 183.33)"
        style={{
          isolation: "isolate",
        }}
        fontSize={43}
        fontFamily="Helvetica"
        letterSpacing=".01em"
        fill="#b70e0e"
        id="prefix__CULTURE"
      >
        {"CU"}
        <tspan x={62.88} y={0} letterSpacing="-.06em">
          {"L"}
        </tspan>
        <tspan x={84.01} y={0}>
          {"TURE"}
        </tspan>
      </text>
    </svg>
  );
}

const MemoImmersedInCulture = React.memo(ImmersedInCulture);
export default MemoImmersedInCulture;
