import React from 'react'

import Header from 'components/header'
import Footer from 'components/footer'
import SEO from 'components/seo'
import Banner from 'components/pages/home/banner'
import WhoIsLittleScholar from 'components/pages/home/who-is'
import OurPrograms from 'components/pages/home/our-programs'
import 'typeface-josefin-slab'
import 'styles/tailwind.css'

/**
 * Home Page
 */
const IndexPage = () => (
  <>
    <SEO title="Home" />
    <Header marginBottom={false} />
    <main className="mx-auto flex-1">
      <Banner />
      <WhoIsLittleScholar />
      <OurPrograms />
    </main>
    <Footer />
  </>
)

export default IndexPage
