import * as React from "react";

function little(props) {
  return (
    <svg
      id="prefix__Layer_1"
      x={0}
      y={0}
      viewBox="0 0 230 86"
      xmlSpace="preserve"
      {...props}
    >
      <style>{".prefix__st5{fill:#ff8900}"}</style>
      <path
        d="M99.41 5.95a2.601 2.601 0 00-2.06-1.36c-.67-.08-1.36.11-1.91.54-.16.13-.3.28-.43.44-.05-.01-.1-.03-.16-.04-1.54-.23-2.91.78-3.14 2.29-.62 4.11-1.24 8.44-1.79 12.89-2.41.18-4.81.42-7.21.74-1 .13-1.76.79-2.09 1.66-.9.45-1.48 1.42-1.43 2.52.04.74.36 1.39.85 1.84.13 1.37 1.24 2.4 2.64 2.4h.04c2.05-.03 4.12-.07 6.22-.14-.68 7.5-1.02 15.24-.66 22.95.32 6.93 1.14 24.14 12.15 28.87 1.19.66 2.59 1.08 4.06 1.21.19.07.39.13.59.15.76.1 1.51.15 2.23.15 6.32 0 9.91-3.61 11.18-6.98 1.2-3.2.4-6.42-1.99-8.01-.42-.28-.89-.41-1.37-.42-1.29-.42-2.65.08-3.3 1.28-1.59 2.92-3.74 4.79-5.6 4.88-1.4.07-2.71-.85-3.99-2.72-.91-1.34-1.35-3.32-1.73-5.07-1.22-5.54-1.24-11.96-1.16-17.56.1-6.91.29-13.37.58-19.48 4.11-.46 8.21-1.16 12.24-2.29.01 0 .01 0 .02-.01 1.39-.39 2.22-1.68 2-3.07-.1-.61-.41-1.13-.83-1.53a2.607 2.607 0 00-2.5-1.86c-3.59-.02-7.04-.02-10.43.04.27-4.02.58-7.89.95-11.62.12-1.29-.73-2.42-1.97-2.69z"
        fill="#7cc000"
      />
      <path
        d="M49.52 72.23c-13.24-2.13-25.24-2.26-36.49-.42.13-3.71.29-7.43.44-11.08.77-18.2 1.58-37.01-.94-55.19-.2-1.47-1.47-2.46-2.93-2.31-.02 0-.04.01-.06.01-.34-.17-.71-.29-1.11-.31-1.38-.1-2.55.89-2.75 2.24-.16-.02-.33-.02-.5-.01-1.43.09-2.54 1.23-2.57 2.65l-.04 1.81c-.52 23.1-1.05 46.99.22 70.43 0 .03.01.05.01.07-.03.3-.03.61.05.93a2.58 2.58 0 003.12 1.96c7.1-1.62 14.44-1.43 21.53-1.25 6.97.18 14.18.36 21.36-1.1 1.48-.3 2.4-1.71 2.1-3.21-.01-.03-.02-.06-.03-.1a2.89 2.89 0 001.08-2.37c-.07-1.39-1.09-2.52-2.49-2.75z"
        fill="#ff3352"
      />
      <path
        d="M177.4 83.05c.14.02.28.03.42.03.94 0 1.77-.46 2.27-1.23.34.13.69.22 1.06.22.42 0 .85-.09 1.24-.28.92-.43 1.52-1.3 1.61-2.32 2.32-26.55 2.36-48.6.14-69.37-.09-.82-.54-1.49-1.17-1.9.04-.18.06-.36.09-.54.2-.97-.1-1.93-.82-2.57-.69-.62-1.67-.85-2.6-.62a2.5 2.5 0 00-1.09-.43c-1.49-.23-2.86.73-3.12 2.21-1.82 10.3-1.41 21.07-1.01 31.48.08 2.05.16 4.08.22 6.1v.16c.37 12.36.71 24.03.26 36.12-.05 1.46 1.02 2.72 2.5 2.94z"
        fill="#ff4191"
      />
      <path
        d="M212.37 83.08h.3c4.39-.08 8.4-1.98 11.26-5.15.4-.39.78-.8 1.13-1.23.23-.28.39-.59.49-.91.09-.14.19-.26.27-.4.15-.25.24-.51.3-.77.25-.66.24-1.41-.1-2.1-.54-1.1-1.48-1.83-2.64-2.07-2.69-.54-5.6 1.75-8.61 4.36-.3.26-.54.47-.67.57-.68.51-1.38.66-2.21.47-1.88-.44-3.79-2.46-4.62-4.14-.35-.7-.64-1.47-.88-2.31.4.03.81.05 1.2.05 6.7 0 12.79-4.57 16.37-9.97 3.7-5.58 5.53-13.27 2.57-19.57-1.03-3.12-3.08-5.35-5.98-6.39-4.85-1.76-11.38.16-15.89 4.64-9.24 9.2-11.74 27.63-5.14 37.91 2.82 4.4 7.6 7.01 12.85 7.01zm7.79-34.76c-.78 2.61-2.43 5.34-5.03 8.35-1.98 2.28-6.03 5.41-9.54 5.41h-.03c.03-1.62.17-3.06.3-4.09.44-3.44 1.8-6.96 4.27-11.1 1.49-2.49 4.38-5.41 6.79-5.92.83-.17 1.51-.03 2.17.43 1.79 1.28 1.83 4.39 1.07 6.92z"
        fill="#00a9eb"
      />
      <path
        d="M144.24 5.95a2.601 2.601 0 00-2.06-1.36c-.67-.08-1.36.11-1.91.54-.16.13-.3.28-.43.44-.05-.01-.1-.03-.16-.04-1.54-.23-2.91.78-3.14 2.29-.62 4.11-1.24 8.44-1.79 12.89-2.41.18-4.81.42-7.21.74-1 .13-1.76.79-2.09 1.66-.9.45-1.48 1.42-1.43 2.52.04.74.36 1.39.85 1.84.13 1.37 1.24 2.4 2.64 2.4h.04c2.05-.03 4.12-.07 6.22-.14-.68 7.5-1.02 15.24-.66 22.95.32 6.93 1.14 24.14 12.15 28.87 1.19.66 2.59 1.08 4.06 1.21.19.07.39.13.59.15.76.1 1.51.15 2.23.15 6.32 0 9.91-3.61 11.18-6.98 1.2-3.2.4-6.42-1.99-8.01-.42-.28-.89-.41-1.37-.42-1.29-.42-2.65.08-3.3 1.28-1.59 2.92-3.74 4.79-5.6 4.88-1.4.07-2.71-.85-3.99-2.72-.91-1.34-1.35-3.32-1.73-5.07-1.22-5.54-1.24-11.96-1.16-17.56.1-6.91.29-13.37.58-19.48 4.11-.46 8.21-1.16 12.24-2.29.01 0 .01 0 .02-.01 1.39-.39 2.22-1.68 2-3.07-.1-.61-.41-1.13-.83-1.53a2.607 2.607 0 00-2.5-1.86c-3.59-.02-7.04-.02-10.43.04.27-4.02.58-7.89.95-11.62.12-1.29-.73-2.42-1.97-2.69z"
        fill="#937ab3"
      />
      <path
        className="prefix__st5"
        d="M65.23 82.63h.3c.66-.04 1.26-.31 1.72-.72.54.69 1.39 1.09 2.35 1.04h.04c1.48-.11 2.62-1.29 2.66-2.75.17-7.88-.35-15.82-.84-23.5-.47-7.19-.95-14.62-.85-21.91a2.693 2.693 0 00-2.67-2.75c-.13 0-.25 0-.38.02-.11-.09-.23-.16-.36-.23-.92-.51-2.04-.49-2.92.05-.85.51-1.34 1.43-1.32 2.44v.05c-1.31.36-2.2 1.61-2.05 2.98 1.6 14.14 2.14 28.4 1.61 42.39-.06 1.55 1.13 2.82 2.71 2.89zM68.94 26.77l-3.74-1.53c-.34-.14-.72-.1-1.02.1l-3.38 2.21c-.76.5-1.77-.09-1.7-1l.3-4.03c.03-.36-.13-.71-.41-.94l-3.15-2.53c-.71-.57-.46-1.71.43-1.93l3.92-.96c.35-.09.64-.34.77-.68l1.43-3.78c.32-.85 1.48-.97 1.96-.19l2.13 3.43c.19.31.52.5.88.52l4.03.19c.91.04 1.38 1.11.79 1.81l-2.61 3.08a1.1 1.1 0 00-.22 1l1.06 3.9c.24.9-.63 1.67-1.47 1.33z"
      />
    </svg>
  );
}

const Memolittle = React.memo(little);
export default Memolittle;
