import * as React from 'react'

function Gears(props) {
  return (
    <svg viewBox="0 0 762 795.07" {...props}>
      <defs>
        <style>
          {
            '.prefix__cls-4{fill:#496dbf}.prefix__cls-6{fill:#31bc48}.prefix__cls-9{fill:#fff;opacity:.7}.prefix__cls-10,.prefix__cls-14,.prefix__cls-16,.prefix__cls-21,.prefix__cls-9{isolation:isolate}.prefix__cls-14,.prefix__cls-16,.prefix__cls-21{font-size:35px;font-family:Roboto-Regular,Roboto}.prefix__cls-14{fill:#161618}.prefix__cls-16{fill:#0d0d11}.prefix__cls-21{fill:#09090d}'
          }
        </style>
      </defs>
      <g id="prefix__objects">
        <g id="prefix__objects-2" data-name="objects">
          <g id="prefix__Group_449" data-name="Group 449">
            <path
              id="prefix__Path_455"
              data-name="Path 455"
              d="M746.7 379l-23.84-5.13c-14.41-3.1-15.12-23.4-1-27.5l23.46-6.76c17.28-5 11.2-30.83-6.5-27.59l-24 4.38c-14.5 2.66-22.93-15.83-11.42-25l19.09-15.29c14-11.23-1.46-32.76-16.57-23l-20.48 13.23c-12.39 8-27.24-5.85-20.13-18.77l11.77-21.35c8.68-15.76-13.88-29.72-24.1-14.92l-13.86 20.06c-8.38 12.14-27.41 5-25.78-9.63l2.7-24.24c2-17.87-24.2-22.14-28-4.55l-5.13 23.84c-3.1 14.41-23.4 15.12-27.5 1l-6.78-23.42c-5-17.28-30.83-11.2-27.59 6.5l4.38 24c2.65 14.5-15.83 22.93-25 11.42l-15.23-19.08c-11.24-14-32.77 1.46-23 16.57l13.23 20.48c8 12.39-5.85 27.24-18.77 20.13l-21.4-11.79c-15.75-8.68-29.71 13.88-14.91 24.1l20.06 13.86c12.13 8.38 5 27.41-9.64 25.78l-24.27-2.74c-17.88-2-22.14 24.2-4.55 28l23.83 5.13c14.42 3.1 15.13 23.4 1 27.5L403.34 391c-17.28 5-11.2 30.83 6.5 27.59l24-4.38c14.5-2.65 22.93 15.83 11.41 25l-19 15.23c-14.05 11.23 1.45 32.76 16.56 23l20.48-13.22c12.39-8 27.25 5.86 20.13 18.77l-11.77 21.36c-8.68 15.76 13.88 29.71 24.1 14.91l13.87-20.06c8.38-12.13 27.41-5 25.78 9.64l-2.7 24.23c-2 17.88 24.19 22.15 28 4.56l5.13-23.84c3.1-14.42 23.4-15.13 27.51-1l6.78 23.42c5 17.28 30.82 11.2 27.59-6.5l-4.39-24c-2.65-14.5 15.84-22.93 25-11.41l15.22 19c11.24 14.05 32.77-1.45 23-16.56l-13.22-20.48c-8-12.39 5.86-27.25 18.77-20.13l21.36 11.76c15.75 8.69 29.71-13.88 14.91-24.1l-20.06-13.86c-12.13-8.38-5-27.41 9.64-25.78l24.23 2.7c17.83 2.09 22.12-24.09 4.53-27.85zm-172.36 90.53a104.25 104.25 0 11104.25-104.25 104.26 104.26 0 01-104.24 104.25z"
              transform="translate(-206 -10)"
              fill="#e61e43"
            />
            <path
              id="prefix__Path_456"
              data-name="Path 456"
              d="M955.54 457.4l-12.79-2.74a7.62 7.62 0 01-.52-14.76l12.57-3.64c9.27-2.68 6-16.53-3.49-14.8l-12.82 2.36a7.61 7.61 0 01-6.12-13.44l10.12-8.17c7.54-6-.78-17.58-8.88-12.34l-11 7.1a7.61 7.61 0 01-10.8-10.07l6.31-11.45c4.66-8.46-7.44-15.95-12.93-8l-7.44 10.76a7.62 7.62 0 01-13.83-5.21l1.45-13c1.07-9.59-13-11.88-15-2.44l-2.75 12.78a7.62 7.62 0 01-14.76.52l-3.63-12.57c-2.69-9.27-16.54-6-14.8 3.49l2.35 12.87a7.61 7.61 0 01-13.44 6.12l-8.13-10.22c-6-7.53-17.58.78-12.34 8.89l7.1 11a7.61 7.61 0 01-10.07 10.8L788.49 395c-8.45-4.66-15.94 7.44-8 12.93l10.77 7.43a7.62 7.62 0 01-5.17 13.84l-13-1.45c-9.59-1.07-11.88 13-2.45 15l12.8 2.74a7.61 7.61 0 01.56 14.71l-12.56 3.64c-9.27 2.69-6 16.54 3.48 14.8l12.87-2.35a7.61 7.61 0 016.12 13.43l-10.21 8.17c-7.54 6 .78 17.58 8.88 12.34l11-7.1a7.61 7.61 0 0110.8 10.07L808 524.66c-4.66 8.45 7.45 15.94 12.93 8l7.44-10.76a7.61 7.61 0 0113.83 5.17l-1.45 13c-1.07 9.59 13 11.87 15 2.44l2.72-12.78a7.62 7.62 0 0114.76-.52l3.63 12.57c2.69 9.27 16.54 6 14.8-3.49l-2.35-12.86a7.61 7.61 0 0113.43-6.13l8.17 10.22c6 7.54 17.58-.78 12.35-8.89l-7.1-11a7.61 7.61 0 0110.07-10.79l11.45 6.31c8.46 4.66 15.94-7.45 8-12.93l-10.76-7.44a7.61 7.61 0 015.19-13.78l13 1.45c9.58 1.03 11.89-13.02 2.43-15.05zm-92.48 23.19A30.56 30.56 0 11893.62 450a30.56 30.56 0 01-30.56 30.59z"
              transform="translate(-206 -10)"
              fill="#fc7d69"
            />
            <path
              id="prefix__Path_457"
              data-name="Path 457"
              d="M410.94 521.59l-1.71-.71a14 14 0 011-26.18l1.75-.59c16.46-5.48 10.2-30.08-6.87-27l-1.82.33c-12.73 2.27-21.46-12.47-13.35-22.55l1.16-1.44c10.88-13.5-7.68-30.82-20.4-19l-1.36 1.26c-9.48 8.8-24.8 1.11-23.41-11.75l.2-1.84c1.85-17.24-23.13-21.77-27.45-5l-.47 1.79c-3.22 12.52-20.26 14.34-26 2.78l-.83-1.66c-7.76-15.51-31.23-5.81-25.78 10.65l.58 1.76c4.06 12.28-9.29 23-20.41 16.42l-1.6-.94c-14.91-8.85-29.41 12-15.93 22.9l1.44 1.16c10.05 8.14 4.63 24.39-8.3 24.85l-1.85.07c-17.33.62-18.26 26-1 27.88l1.84.2c12.85 1.41 17.08 18 6.45 25.39l-1.52 1.06c-14.24 9.89-1.31 31.74 14.22 24l1.65-.82c11.58-5.77 24.11 5.92 19.16 17.87l-.71 1.71c-6.63 16 16.06 27.41 24.94 12.51l.95-1.59c6.62-11.11 23.48-8.05 25.78 4.68l.25 1.8c3.08 17.07 28.32 14.37 27.74-3l-.06-1.85c-.44-12.92 15.4-19.47 24.21-10l1.26 1.36c11.82 12.69 31.6-3.22 21.74-17.49l-1.05-1.52c-7.35-10.64 2.43-24.7 15-21.51l1.8.46c16.73 4.3 24.82-19.78 8.76-26.45zm-108.11 39.87a59.78 59.78 0 1171.5-45.11 59.76 59.76 0 01-71.48 45.11z"
              transform="translate(-206 -10)"
              fill="#67c1f9"
            />
            <path
              id="prefix__Path_458"
              data-name="Path 458"
              className="prefix__cls-4"
              d="M841.28 334.7l-1.32-.55a4.74 4.74 0 01.63-8.94l1.38-.36c5.7-1.51 4-10-1.8-9.27l-1.42.17a4.73 4.73 0 01-3.93-8.05l1-1c4.19-4.16-1.49-10.69-6.2-7.13l-1.13.87a4.73 4.73 0 01-7.43-5l.37-1.38c1.55-5.7-6.64-8.51-8.93-3.07l-.5 1.31a4.74 4.74 0 01-8.94-.62l-.36-1.38c-1.51-5.71-10-4-9.27 1.8l.18 1.42a4.74 4.74 0 01-8 3.93l-1-1c-4.16-4.18-10.7 1.5-7.13 6.2l.86 1.14a4.73 4.73 0 01-5 7.43l-1.37-.38c-5.7-1.54-8.52 6.64-3.08 8.93l1.32.56a4.73 4.73 0 01-.63 8.93l-1.37.37c-5.71 1.51-4.06 10 1.8 9.27l1.41-.18a4.73 4.73 0 013.93 8l-1 1c-4.19 4.16 1.5 10.69 6.2 7.12l1.13-.86a4.73 4.73 0 017.43 5l-.37 1.38c-1.55 5.7 6.64 8.51 8.93 3.07l.55-1.31a4.74 4.74 0 018.94.62l.36 1.38c1.51 5.7 10 4.05 9.27-1.8l-.18-1.42a4.73 4.73 0 018-3.92l1 1c4.17 4.18 10.7-1.5 7.13-6.2l-.86-1.15a4.73 4.73 0 015-7.42l1.38.37c5.64 1.59 8.46-6.58 3.02-8.88zM805 344.26a17 17 0 1117-17 17 17 0 01-17 17z"
              transform="translate(-206 -10)"
            />
            <path
              id="prefix__Path_459"
              data-name="Path 459"
              className="prefix__cls-4"
              d="M616.55 125.15a10.13 10.13 0 01-1.88-18.26c10.61-6.35 2.74-22.55-8.81-18.14A10.13 10.13 0 01592.68 76c4-11.68-12.4-19-18.41-8.23A10.12 10.12 0 01556 66.44c-4.42-11.55-21.74-6.62-19.4 5.52a10.12 10.12 0 01-14.9 10.76c-10.8-6-20.9 8.9-11.3 16.7a10.13 10.13 0 01-4.47 17.8c-12.15 2.35-10.29 20.26 2.07 20.07a10.11 10.11 0 018 16.5c-7.8 9.6 5.13 22.13 14.48 14a10.13 10.13 0 0116.75 7.49c.2 12.37 18.16 13.66 20.12 1.44a10.12 10.12 0 0117.65-5c8.1 9.34 22.68-1.22 16.34-11.83a10.12 10.12 0 0110.28-15.2c12.23 1.97 16.61-15.5 4.93-19.54zM559.71 150A27.1 27.1 0 11588 124.14 27.1 27.1 0 01559.71 150z"
              transform="translate(-206 -10)"
            />
            <path
              id="prefix__Path_460"
              data-name="Path 460"
              d="M612 700.73c-12.82-5.64-10.31-24.54 3.55-26.63 17.41-2.62 15-28.42-2.57-27.81-14 .48-19.94-17.64-8.36-25.54 14.55-9.91 1.22-32.12-14.37-23.94-12.41 6.51-25.62-7.24-18.62-19.38 8.8-15.25-12.84-29.47-23.34-15.33-8.35 11.25-26.22 4.59-25.18-9.38 1.32-17.56-24.36-21-27.68-3.69-2.64 13.77-21.64 15.52-26.76 2.47-6.43-16.39-31-8.33-26.54 8.69 3.59 13.55-12.76 23.37-23 13.83-12.91-12-31.59 6-20.14 19.35 9.11 10.65-1.36 26.59-14.75 22.46-16.83-5.19-25.87 19.08-9.76 26.17 12.83 5.64 10.31 24.55-3.54 26.63-17.41 2.63-15 28.42 2.56 27.81 14-.48 19.94 17.64 8.37 25.54-14.55 9.92-1.22 32.12 14.37 23.94 12.41-6.51 25.62 7.24 18.62 19.38-8.8 15.25 12.84 29.47 23.34 15.33 8.35-11.25 26.23-4.59 25.18 9.39-1.32 17.55 24.36 21 27.68 3.68 2.64-13.76 21.64-15.51 26.76-2.47 6.43 16.39 31 8.33 26.54-8.69-3.59-13.55 12.76-23.37 23-13.83 12.91 12 31.59-6 20.14-19.35-9.11-10.65 1.36-26.59 14.76-22.46 16.8 5.19 25.85-19.08 9.74-26.17zm-136.86 57.14a88.37 88.37 0 11104.58-68.43 88.37 88.37 0 01-104.57 68.43z"
              transform="translate(-206 -10)"
              fill="#e32b7e"
            />
            <path
              id="prefix__Path_461"
              data-name="Path 461"
              className="prefix__cls-6"
              d="M835.94 615c-12.6-3.71-13.34-21.29-1.09-26 16-6.22 8.48-30.25-8.22-26.22-12.77 3.08-22.2-11.77-14-22 10.76-13.39-7.79-30.44-20.23-18.6-9.52 9-25.11.9-23.1-12.08 2.62-17-22-22.47-26.82-6-3.71 12.6-21.29 13.34-26 1.09-6.22-16-30.26-8.48-26.23 8.22 3.09 12.77-11.76 22.2-22 14-13.39-10.75-30.44 7.79-18.6 20.23 9.06 9.52.91 25.11-12.08 23.1-17-2.62-22.47 22-6 26.82 12.6 3.71 13.34 21.29 1.09 26-16 6.22-8.47 30.26 8.22 26.22 12.77-3.08 22.2 11.77 14 22-10.76 13.38 7.78 30.44 20.23 18.6 9.51-9.06 25.1-.91 23.1 12.07-2.63 17 22 22.47 26.81 6 3.72-12.6 21.29-13.34 26-1.09 6.21 16 30.25 8.48 26.22-8.21-3.08-12.78 11.77-22.2 22-14 13.39 10.76 30.44-7.79 18.6-20.23-9-9.52-.9-25.11 12.08-23.1 17 2.65 22.5-21.93 6.02-26.82zm-100.68 52.2a61 61 0 1159.45-62.51 61 61 0 01-59.45 62.54z"
              transform="translate(-206 -10)"
            />
            <path
              id="prefix__Path_462"
              data-name="Path 462"
              d="M481.67 165.36a17.48 17.48 0 01.92-31.65c19.6-8.45 9.82-37.95-10.94-33A17.46 17.46 0 01452 75.88c9.57-19.11-16.88-35.43-29.62-18.29a17.47 17.47 0 01-31-6.4c-4.9-20.78-35.68-16.28-34.45 5a17.47 17.47 0 01-27.86 15c-17.12-12.74-37.78 10.47-23.14 26a17.47 17.47 0 01-11.69 29.42c-21.3 1.25-22.21 32.31-1 34.79a17.47 17.47 0 0110 30.06c-15.52 14.64 3.76 39 21.59 27.3a17.46 17.46 0 0126.94 16.62c-2.47 21.19 28 27.48 34.09 7a17.46 17.46 0 0131.33-4.59c11.73 17.83 39.08 3.08 30.63-16.52a17.47 17.47 0 0121.05-23.65c20.4 6.2 31.87-22.68 12.8-32.26zm-102.32 26.9a44 44 0 1150.91-35.79v.05a44 44 0 01-50.91 35.74z"
              transform="translate(-206 -10)"
              fill="#efb93f"
            />
            <path
              id="prefix__Path_463"
              data-name="Path 463"
              d="M928.14 164c-15.52-6-15.34-28 .28-33.68 21-7.64 12-39-9.81-34.5-16.28 3.38-28-15.25-18-28.48 13.49-17.76-11-39.33-26.91-23.72-11.87 11.69-31.83 2.38-30.52-14.2 1.75-22.23-30.51-27.15-35.46-5.4-3.68 16.21-25.51 19.16-33.37 4.51C763.81 8.88 734 22.19 741.6 43.16 747.29 58.79 730.49 73.07 716 65c-19.49-10.83-37.36 16.48-19.64 30 13.21 10.09 6.83 31.17-9.76 32.23-22.25 1.43-22.52 34.07-.3 35.87 16.57 1.34 22.6 22.53 9.22 32.39-18 13.23-.53 40.84 19.14 30.34 14.66-7.83 31.19 6.73 25.27 22.26-7.95 20.84 21.63 34.64 32.5 15.18 8.1-14.52 29.88-11.2 33.3 5.07 4.58 21.82 36.92 17.45 35.54-4.81-1-16.59 19.08-25.57 30.75-13.73 15.65 15.88 40.5-5.29 27.3-23.26-9.84-13.4 2.23-31.83 18.45-28.18C939.49 203.27 949 172 928.14 164zM805 218a71.67 71.67 0 1171.67-71.67A71.67 71.67 0 01805 218z"
              transform="translate(-206 -10)"
              fill="#f5720d"
            />
            <g id="prefix__Group_448" data-name="Group 448">
              <path
                id="prefix__Path_464"
                data-name="Path 464"
                className="prefix__cls-6"
                d="M379.1 320.75a7.31 7.31 0 01-5.94-11.84c5.54-7-3.91-15.93-10.59-10a7.31 7.31 0 01-12.16-5.25c-.26-8.92-13.24-9.69-14.54-.85a7.31 7.31 0 01-12.69 3.8c-5.94-6.67-16.37 1.09-11.69 8.69a7.31 7.31 0 01-7.29 11.07c-8.83-1.3-11.83 11.35-3.36 14.17a7.31 7.31 0 011.53 13.16c-7.6 4.69-1.77 16.31 6.53 13a7.32 7.32 0 019.64 9.1c-2.81 8.47 9.13 13.63 13.37 5.77a7.3 7.3 0 019.89-3 7.23 7.23 0 013.33 3.75c3.3 8.3 15.76 4.57 14-4.17a7.31 7.31 0 0110.63-7.91c7.86 4.24 15-6.62 8-12.17a7.31 7.31 0 013.06-12.89c8.72-1.76 7.18-14.68-1.72-14.43zm-37.88 34.67a23.65 23.65 0 1123.65-23.65 23.64 23.64 0 01-23.65 23.65z"
                transform="translate(-206 -10)"
              />
            </g>
          </g>
        </g>
        <rect
          id="prefix__Rectangle_179"
          data-name="Rectangle 179"
          className="prefix__cls-9"
          x={74}
          y={109}
          width={206}
          height={54}
          rx={14}
        />
        <rect
          id="prefix__Rectangle_180"
          data-name="Rectangle 180"
          className="prefix__cls-9"
          x={258}
          y={304}
          width={218}
          height={97}
          rx={14}
        />
        <rect
          id="prefix__Rectangle_181"
          data-name="Rectangle 181"
          className="prefix__cls-9"
          x={14}
          y={454}
          width={188}
          height={97}
          rx={14}
        />
        <rect
          id="prefix__Rectangle_182"
          data-name="Rectangle 182"
          className="prefix__cls-9"
          x={431}
          y={549}
          width={188}
          height={97}
          rx={14}
        />
        <rect
          id="prefix__Rectangle_183"
          data-name="Rectangle 183"
          className="prefix__cls-9"
          x={511}
          y={107}
          width={177}
          height={54}
          rx={14}
        />
        <rect
          id="prefix__Rectangle_184"
          data-name="Rectangle 184"
          className="prefix__cls-9"
          x={557}
          y={413}
          width={205}
          height={54}
          rx={14}
        />
        <text
          transform="translate(520.02 146)"
          style={{
            isolation: 'isolate',
          }}
          fill="#16191d"
          fontSize={35}
          fontFamily="Roboto-Regular,Roboto"
          id="prefix__Standards"
        >
          {'Standards'}
        </text>
        <text
          transform="translate(78.31 146)"
          style={{
            isolation: 'isolate',
          }}
          fill="#12161c"
          fontSize={35}
          fontFamily="Roboto-Regular,Roboto"
          id="prefix__Environment"
        >
          {'Envi'}
          <tspan x={64.65} y={0} letterSpacing="-.01em">
            {'r'}
          </tspan>
          <tspan x={76.15} y={0}>
            {'onment'}
          </tspan>
        </text>
        <g
          id="prefix__Materials_Resources"
          data-name="Materials Resources"
          className="prefix__cls-10"
        >
          <text className="prefix__cls-14" transform="translate(20.55 487)">
            {'Materials &'}
          </text>
          <text className="prefix__cls-14" transform="translate(26.59 533)">
            {'Resou'}
            <tspan x={97.4} y={0} letterSpacing="-.01em">
              {'r'}
            </tspan>
            <tspan x={108.91} y={0}>
              {'ces'}
            </tspan>
          </text>
        </g>
        <g
          id="prefix__Curriculum_Framework"
          data-name="Curriculum Framework"
          className="prefix__cls-10"
        >
          <text className="prefix__cls-16" transform="translate(439.33 583)">
            {'Curriculum'}
          </text>
          <text className="prefix__cls-16" transform="translate(437.42 629)">
            <tspan letterSpacing="-.01em">{'F'}</tspan>
            <tspan x={18.9} y={0} letterSpacing="-.02em">
              {'r'}
            </tspan>
            <tspan x={30.06} y={0}>
              {'amework'}
            </tspan>
          </text>
        </g>
        <text
          transform="translate(561.74 450)"
          style={{
            isolation: 'isolate',
          }}
          fill="#171a1e"
          fontSize={35}
          fontFamily="Roboto-Regular,Roboto"
          id="prefix__Assessment"
        >
          {'Assessment'}
        </text>
        <text
          transform="translate(202.52 670)"
          style={{
            isolation: 'isolate',
          }}
          fill="#111316"
          fontSize={35}
          fontFamily="Roboto-Regular,Roboto"
          id="prefix__Instruction"
        >
          {'Instruction'}
        </text>
        <g
          id="prefix__Student_Achievement"
          data-name="Student Achievement"
          className="prefix__cls-10"
        >
          <text className="prefix__cls-21" transform="translate(306.24 337)">
            {'Student'}
          </text>
          <text className="prefix__cls-21" transform="translate(265.15 383)">
            {'Achi'}
            <tspan x={68.92} y={0} letterSpacing="-.01em">
              {'e'}
            </tspan>
            <tspan x={87.24} y={0}>
              {'vement'}
            </tspan>
          </text>
        </g>
      </g>
    </svg>
  )
}

const MemoGears = React.memo(Gears)
export default MemoGears
