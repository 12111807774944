import React from 'react'
import { useMediaQuery } from 'react-responsive'

import ContainedButton from 'components/buttons/contained-button'

import Little from 'components/svgs/little'
import Gears from 'components/pages/home/graphics/Gears'
import KidSlide from 'static/images/home/kids-slide.jpg'
import Bullet from 'static/svgs/bullet-arrow.svg'
import StudentInterdisciplinary from 'components/pages/home/graphics/StudentInterdisciplinaryFocused'
import PlayBasedLearning from 'components/pages/home/graphics/PlayBasedLearning'
import DevelopmentallyAppropriate from 'components/pages/home/graphics/DevelopmentallyAppropriate'
import AmericanCurriculum from 'components/pages/home/graphics/AmericanCurriculum'
import StemInfusedStream from 'components/pages/home/graphics/StemInfusedStream'
import MasterEnglishLanguage from 'components/pages/home/graphics/MasterEnglishLanguage'
import ImmersedInCulture from 'components/pages/home/graphics/ImmersedInCulture'

const WhoIsLittleScholar = () => {
  const isSmallScreen = useMediaQuery({ query: '(max-device-width: 450px)' })
  const isMdAbove = useMediaQuery({ query: '(min-device-width: 768px)' })
  // const isMdScreen = useMediaQuery({
  //   query: '(min-device-width: 768px) and (max-device-width: 1024px)',
  // })

  return (
    <div
      id="who-is"
      className="container mx-auto divide-y-2 px-4 py-4 md:py-6 text-center md:text-left"
    >
      <div className="w-full pb-6 md:py-16 flex flex-col md:flex-row md:space-x-12">
        <img
          className="mx-auto md:mx-0 max-w-xs md:max-w-none md:w-2/5 my-6 md:my-0"
          style={{ order: isSmallScreen ? 4 : null }}
          src={KidSlide}
        />
        <div className="py-4 md:py-10 flex flex-col space-y-10 items-start">
          <h1 className="w-full flex flex-col flex-wrap lg:flex-row text-4xl lg:text-5xl ">
            <nobr className="pr-4">WHO IS </nobr>
            <nobr className="flex flex-row justify-center md:justify-start">
              <Little className="h-10 lg:h-14 pr-4" />

              <span>{' SCHOLARS?'}</span>
            </nobr>
          </h1>
          <p className="text-primary-800 md:max-w-lg">
            <span className="font-bold">Little Scholar (LS)</span> is an
            organization dedicated to delivering a student focused and highly
            contextualized American English learning program that accommodates
            all levels of proficiency.
          </p>

          <div className="flex flex-row space-x-8">
            {/* {isMdScreen && (
              <img
                className="mx-auto md:mx-0 max-w-xs md:max-w-none md:w-2/5 my-6 md:my-0"
                src={KidSlide}
              />
            )} */}

            <div className="flex flex-col">
              <h2>OUR MISSION</h2>
              <p className="md:max-w-lg lg:max-w-xl ">
                We aim to create a developmentally appropriate learning
                experience for a child new to English Language learning.
              </p>
            </div>
          </div>
        </div>
      </div>

      <div className="w-full py-4 md:py-16 flex flex-col lg:flex-row-reverse space-y-6 lg:space-y-0 justify-end">
        <Gears
          className="w-full px-6 my-8 md:w-3/5 lg:w-2/5 mx-auto"
          style={{ order: isSmallScreen ? 4 : null }}
        />

        <div className="lg:mr-8">
          <h2 className="mb-10">ALIGNED WITH STATE STANDARDS</h2>
          <ul
            className="pl-10 lg:max-w-md text-primary-800 flex flex-col space-y-4 text-left"
            style={{ listStyleImage: `url(${Bullet})` }}
          >
            <li>Research based and Standards Aligned Curriculum</li>
            <li>
              Professional Training
              <ul
                className="pl-10 mt-4 flex flex-col space-y-4"
                style={{ listStyleImage: `url(${Bullet})` }}
              >
                <li>Assessments</li>
                <li>Teacher Instruction</li>
                <li>Effective learning environment</li>
                <li>Student Engagement</li>
              </ul>
            </li>
            <li>
              Interactive Curriculum with finger plays, songs, movement,
              activities, stories and more
            </li>
          </ul>
        </div>
      </div>

      {/* <div className="py-16 flex flex-row">
        Auto slider
      </div> */}
    </div>
  )
}

export default WhoIsLittleScholar
