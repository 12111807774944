import React from 'react'

import KidsBanner from 'static/images/home/kids-banner.jpg'
import Logo from 'components/logo'
import Mile from 'components/pages/home/graphics/MileHeader'
import Millie from 'components/pages/home/graphics/MillieHeader'

const Banner = () => {
  return (
    <div className="relative md:mb-24 lg:mb-32 flex flex-col">
      <p className="text-2xl md:text-5xl font-serif text-center text-primary-800 font-bold py-2 border-b-14 border-primary-200">
        ACADEMIC EXCELLENCE FOR <nobr>DEVELOPING MINDS</nobr>
      </p>

      <img
        className="border-b-12 border-primary-800"
        src={KidsBanner}
        alt="banner"
      />

      <div className="md:absolute md:left-0 md:bottom-minus8rem pb-8 w-full flex flex-col items-center justify-center border-b-12 border-primary-800">
        <div className="flex flex-row items-end h-40 md:h-60 lg:h-80">
          <Mile className="h-full" />
          <Logo className="lg:w-64 h-2/3 max-h-full" />
          <Millie className="h-full" />
        </div>
        <p className="mt-4 md:mt-0 font-serif font-semibold text-center text-xl md:text-3xl text-primary-800">
          Embrace the future with Little Scholars
        </p>
      </div>
    </div>
  )
}

export default Banner
